import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBiometricVerificationResults, ISignRequestVerificationLinkResponseDto } from '@literax/models/participant.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService extends BaseService<any> {

  constructor(http: HttpClient) {
    super(http);
  }

  public generateTwoFactorToken(documentId: number, signRequestId: number, token?: string): Observable<any> {
    const path = `/documents/${documentId}/signrequests/${signRequestId}/two_factor_tokens`;
    if (token) {
      return this.post(path, null, true, { headers: this.createHeader(token) });
    } else {
      return this.post(path, null, true);
    }
  }

  public getRequestBiometricValidationLink(documentId: number, signRequestId: number, token?: string): Observable<ISignRequestVerificationLinkResponseDto> {
    const path = `/migr/connectors/neocheck/v3/documents/${documentId}/signrequests/${signRequestId}/request_biometric_link`;
    if(token) {
      return this.getConnector(path, token);
    } 
    return this.getConnector(path);
  }

  public getVerificationResults(documentId: number, signRequestId: number, token?: string): Observable<IBiometricVerificationResults> {
    const path = `/migr/connectors/neocheck/v3/documents/${documentId}/signrequests/${signRequestId}/verification_results`;
    if(token) {
      return this.getConnector(path, token);
    } 
    return this.getConnector(path);
  }
}
